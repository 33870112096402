<template>
   <section class="bg-gray-700">
        <div class="max-w-5xl px-6 py-16 mx-auto text-center text-white">
            <h2 class="text-5xl font-semibold text-white">Únete a la plataforma con la cual podrás incrementar tus ventas y llevar tu negocio al siguiente nivel</h2>
            <p class="max-w-lg mx-auto mt-4 text-gray-100">Te ofrecemos acceso a cientos de productos para que inicies tu negocio de inmediato. No neceistas invertir.</p>
    
            <img class="object-cover object-center w-full mt-16 rounded-md shadow "
                src="https://catalog-shop.s3.amazonaws.com/bg-cart.png">
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>