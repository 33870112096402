<template>
  <div>
    <SubHeader />
    <Showcase></Showcase>
    <Languages></Languages> 
    <Stats></Stats>
    <Cloud></Cloud>
  </div>
</template>

<script>
import Showcase from '../components/home/Showcase';
import Stats from '../components/home/Stats';
import Cloud from '../components/home/Cloud';
import Languages from '../components/home/Languages';
import SubHeader from '../components/home/SubHeader.vue';

export default {
  name: 'Home',
  components: {
    Showcase,
    Stats,
  //  Cli,
    Cloud,
    Languages,
    SubHeader
  },
};
</script>

<style>
</style>