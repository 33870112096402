<template>
  <div>
    <div class="table-scroll">
      <table class="ui table">
        <thead>
          <tr>
            <th>#</th>
            <th>Event</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(event, index) in localEvents" v-bind:key="event._id">
            <td>{{ index + 1 }}</td>
            <td>{{ event.event }}</td>
            <td>{{ getFormattedDate(event.eventTime) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <button class="ui button" @click="close">Cerrar</button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'LogViewer',
  props: ['events'],
  data() {
    return {
      localEvents: this.events,
    };
  },
  watch: {
    events: function (val) {
      this.localEvents = val;
    },
  },
  methods: {
    getFormattedDate: function (target) {
      return moment(target).format('DD/MM/yyyy hh:mm a');
    },
    close: function() {
        this.$emit('onClose');
    }
  },
};
</script>

<style>
.table-scroll {
  max-height: 350px;
  overflow-y: scroll;
}
</style>