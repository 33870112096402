import axios from 'axios';

const TOKEN_NAME = 'simpleshop';
const auth = {
    namespaced: true,
    state: () => ({
        isAuthenticated: false,
        accesToken: null,
        user: {
            _id: '',
            name: '',
            lastname: '',
            phone: '',
            email: '',
            permissions: []
        }
    }),
    getters: {
        getAccessToken: state => {
            if (state.accesToken) return state.accesToken;
            else if (localStorage.getItem(TOKEN_NAME)) {
                return localStorage.getItem(TOKEN_NAME);
            } else {
                return '';
            }
        },
        getPermissions: state => state.user.permissions,
        getUser: state => state.user,
        isAuthenticated: state => state.isAuthenticated
    },
    actions: {
        login: ({ commit, getters }, payload) => {
            return new Promise(
                (resolve, reject) => {
                    axios
                        .post(`/apiv2/admin/auth/login`, payload)
                        .then(response => {
                            const data = response.data;
                            if (data.status === 'OK') {
                                commit('LOGIN_REQUEST_SUCCESS', data);
                                resolve({
                                    status: true
                                });
                            } else {
                                commit('LOGIN_REQUEST_FAILED', {});
                                reject({
                                    status: false,
                                    message: 'Usuario y/o contraseña incorrectos'
                                });
                            }
                        })
                        .catch(err => {
                            commit('LOGIN_REQUEST_FAILED', {});
                            reject({
                                status: false,
                                message: 'Usuario y/o contraseña incorrectos'
                            });
                        });
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
        logout: ({ commit, getters }) => {
            commit('LOGOUT');
            commit(
                'INFO_MSG', {
                    title: 'Sesión finalizada!',
                    content: `Vuelve pronto`
                }, { root: true }
            );
        },
        expired: ({ commit, getters }) => {
            commit(
                'WAR_MSG', {
                    title: 'Sesión expirada!',
                    content: `Inicia sesión nuevamente`
                }, { root: true }
            );
            commit('LOGOUT');
        },

        refresh: ({ commit, getters }) => {
            const token = getters.getAccessToken;
            const payload = { token: token };

            return new Promise(
                (resolve, reject) => {
                    axios
                        .post(`/apiv2/admin/auth/refresh`, payload)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('TOKEN_REFRESH_SUCCESS', response.data);
                                resolve({
                                    status: true
                                });
                            } else {
                                commit('TOKEN_REFRESH_FAILED', {});
                                reject({
                                    status: false
                                });
                            }
                        })
                        .catch(err => {
                            commit('TOKEN_REFRESH_FAILED', {});
                            reject({
                                status: false
                            });
                        });
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        }
    },
    mutations: {
        ['LOGIN_REQUEST_SUCCESS']: (state, payload) => {
            state.user = payload.user;
            state.accesToken = payload.access_token;
            state.isAuthenticated = true;
            localStorage.setItem(TOKEN_NAME, payload.access_token);
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + payload.access_token;
        },
        ['TOKEN_REFRESH_SUCCESS']: (state, payload) => {
            state.user = payload.user;
            state.accesToken = payload.access_token;
            state.isAuthenticated = true;
            localStorage.setItem(TOKEN_NAME, payload.access_token);
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + payload.access_token;
        },
        ['TOKEN_REFRESH_FAILED']: (state, payload) => {
            state.user = {
                _id: '',
                name: '',
                lastname: '',
                phone: '',
                email: '',
                permissions: []
            };
            state.accesToken = null;
            state.isAuthenticated = false;
            localStorage.removeItem(TOKEN_NAME);
            delete axios.defaults.headers.common['Authorization'];
        },
        ['LOGIN_REQUEST_FAILED']: (state, payload) => {
            state.user = {
                _id: '',
                name: '',
                lastname: '',
                phone: '',
                email: '',
                permissions: []
            };
            state.accesToken = null;
            state.isAuthenticated = false;
            localStorage.removeItem(TOKEN_NAME);
            delete axios.defaults.headers.common['Authorization'];
        },
        ['LOGOUT']: (state, payload) => {
            state.user = {
                _id: '',
                name: '',
                lastname: '',
                phone: '',
                email: '',
                permissions: []
            };
            state.accesToken = null;
            state.isAuthenticated = false;
            localStorage.removeItem(TOKEN_NAME);
            delete axios.defaults.headers.common['Authorization'];
        }

    }
};

export default auth;