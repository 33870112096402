<template>
  <section class="features-main my-2">
    <div class="container grid grid-3">
      <div class="card flex">
        <i class="fas fa-server fa-3x"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic
          repudiandae nobis earum voluptas ratione corrupti obcaecati
          necessitatibus veritatis praesentium voluptatem id deserunt temporibus
          eius delectus distinctio impedit, maiores velit possimus?
        </p>
      </div>
      <div class="card flex">
        <i class="fas fa-shopping-bag fa-3x"></i>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore quia
          reiciendis quidem ducimus vitae necessitatibus ullam.
        </p>
      </div>
      <div class="card flex">
        <i class="fas fa-laptop-code fa-3x"></i>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo
          ipsam distinctio natus iste esse delectus incidunt quae sit in!
        </p>
      </div>
      <div class="card flex">
        <i class="fas fa-upload fa-3x"></i>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic
          repudiandae nobis earum voluptas ratione corrupti obcaecati
          necessitatibus veritatis praesentium voluptatem id deserunt temporibus
          eius delectus distinctio impedit, maiores velit possimus?
        </p>
      </div>
      <div class="card flex">
        <i class="fas fa-database fa-3x"></i>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste maiores
          expedita minima incidunt provident!
        </p>
      </div>
      <div class="card flex">
        <i class="fas fa-power-off fa-3x"></i>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum, qui.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Main'
};
</script>

<style>
</style>