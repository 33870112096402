<template>
    <section class="bg-gray-700 p-5">
        <div class="container max-w-5xl mx-auto px-4">
    <div class="w-5/5">
      <h1 class="mt-32 text-white text-5xl font-bold">Obtén tu tienda en línea en pocos minutos 
          <span class="text-blue-400"> lista para ofrecer toda la variedad de productos </span>que tenemos disponibles.</h1>
    </div>
    <div class="w-5/6 my-10 ml-6">
      <h3 class="text-gray-300 text-lg">
        La plataforma te permite administrar tus pedidos, clientes<br />
        <strong class="text-white">y sobre todo tus ganacias,</strong>
        para que controles mejor tu negocio.
      </h3>
    </div>
    <div class="hidden sm:block opacity-50 z-0">
      <div class="shadow-2xl w-96 h-96 rounded-full -mt-72"></div>
      <div class="shadow-2xl w-96 h-96 rounded-full -mt-96"></div>
      <div class="shadow-xl w-80 h-80 rounded-full ml-8 -mt-96"></div>
    </div>
    
  </div>
    </section>
</template>
<script>
export default {
    name: 'Stats'
}
</script>

<style>

</style>