<template>
  <div>
      <Head />
      <Cli />
      <Main />
  </div>
</template>

<script>
import Head from '../components/features/Head';
import Cli from '../components/home/Cli';
import Main from '../components/features/Main';
export default {
    name: 'Features',
    components: {
      Head,
      Cli,
      Main
    }
}
</script>

<style>

</style>