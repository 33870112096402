<template>
  <div>
    <form class="ui form" v-on:submit="(e) => e.preventDefault()">
      <div class="inline fields">
        <div class="eight wide field">
          <label>Mes</label>
          <select class="ui fluid dropdown" v-model="tmpPayment.effectiveMonth">
            <option value="1">Enero</option>
            <option value="2">Febrero</option>
            <option value="3">Marzo</option>
            <option value="4">Abril</option>
            <option value="5">Mayo</option>
            <option value="6">Junio</option>
            <option value="7">Julio</option>
            <option value="8">Agosto</option>
            <option value="9">Septiembre</option>
            <option value="10">Octubre</option>
            <option value="11">Noviembre</option>
            <option value="12">Diciembre</option>
          </select>
        </div>
        <div class="four wide field">
          <button class="ui primary button" @click="addPayment">
            Registrar Pago
          </button>
        </div>
      </div>
    </form>

    <div class="table-scroll">
      <table class="ui table">
        <thead>
          <tr>
            <th>#</th>
            <th>Mes Pagado</th>
            <th>Fecha de Pago</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(payment, index) in localPayments"
            v-bind:key="payment._id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ getMonthName(payment.effectiveMonth) }}</td>
            <td>{{ getFormattedDate(payment.paymentDate) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <button class="ui button" @click="close">Cerrar</button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Payments',
  props: ['payments'],
  data() {
    return {
      tmpPayment: {
        amount: 500,
        effectiveMonth: 1,
        effectiveYear: 2021
      },
      localPayments: this.payments,
    };
  },
  watch: {
    payments: function (val) {
      this.localPayments = val;
    },
  },
  methods: {
    getMonthName: function (month) {
      return moment(month, 'M').locale('es_mx').format('MMMM');
    },
    getFormattedDate: function (target) {
      return moment(target).format('DD/MM/yyyy hh:mm a');
    },
    
    addPayment: function () {
      this.$emit('addPayment', this.tmpPayment);
    },
    close: function () {
      this.$emit('onClose');
    },
  },
};
</script>

<style>
</style>