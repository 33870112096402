<template>
  <section class="bg-gray-700">
    <div class="max-w-5xl px-6 py-16 mx-auto">
      <div
        class="
          px-8
          py-12
          bg-gray-800
          rounded-md
          md:px-20
          md:flex
          md:items-center
          md:justify-between
        "
      >
        <div>
          <h3 class="text-2xl font-semibold text-white">
            Solicita una demostración
          </h3>

          <form name="student_application" id="student_application" action="">
            <div class="py-4 px-8">
              <div class="mb-4">
                <label class="block text-white text-sm font-bold mb-2"
                  >Nombre</label
                >
                <input
                  class="border rounded w-full py-2 px-3 text-grey-darker"
                  type="text"
                  name="student_name"
                  id="student_name"
                  v-model="demo.name"
                  placeholder="Ingresa tu nombre"
                />
              </div>

              <div class="mb-4">
                <label class="block text-white text-sm font-bold mb-2"
                  >Correo:</label
                >
                <input
                  class="border rounded w-full py-2 px-3 text-grey-darker"
                  type="text"
                  name="course_name"
                  id="course_name"
                  v-model="demo.email"
                  placeholder="Ingresa tu correo electrónico"
                />
                <p id="error_creater_id"></p>
              </div>
              <div class="mb-4">
                <label class="block text-white text-sm font-bold mb-2"
                  >Teléfono:</label
                >
                <input
                  class="border rounded w-full py-2 px-3 text-grey-darker"
                  type="text"
                  name="course_name"
                  id="course_name"
                  v-model="demo.phone"
                  placeholder="Ingresa tu teléfono"
                />
                <p id="error_creater_id"></p>
              </div>

              <div class="mb-4">
                <button
                  class="
                    text-white
                    mb-2
                    mx-16
                    rounded-full
                    py-1
                    px-24
                    bg-indigo-500
                  "
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
        <span class="text-white text-xl"
          >Uno de nuestros asesores se pondrá en contacto a la brevedad</span
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Cloud',
  data: function () {
    return {
      demoRequested: false,
      demo: {
        name: '',
        email: '',
        phone: '',
      },
    };
  },
  computed: {
    isRequestValid: function () {
      if (
        !this.demoRequested &&
        this.demo.name.trim() &&
        this.demo.email.trim() &&
        this.demo.phone.trim()
      )
        return true;
      else return false;
    },
  },
  methods: {
    clearRequest: function () {
      this.demo = {
        name: '',
        email: '',
        phone: '',
      };
    },
    sendRequest: async function () {
      try {
        if (this.isRequestValid()) {
          await this.$store.dispatch('demo/sendRequest', this.demo);
          this.demoRequested = true;
        } else {
          console.log('Request invalid');
        }
      } catch (error) {
        console.log('Error sending demo request');
      }
    },
  },
};
</script>

<style>
</style>