<template>
  <section class="">
        <div class="max-w-5xl px-6 py-16 mx-auto ">
            <div class="items-center md:flex md:space-x-6">
                <div class="md:w-1/2">
                    <h3 class="text-4xl font-semibold text-gray-800">Contamos con más de 2000 productos listos para envío</h3>
                    <p class="max-w-md mt-4 text-gray-600">La amplia variedad de productos disponibles permite que nuestros clientes encuentren el producto adecuado a sus necesidades.</p>
                    <a href="#" class="block mt-8 text-indigo-700 underline">Experienced team</a>
                </div>
    
                <div class="mt-8 md:mt-0 md:w-1/2">
                    <div class="flex items-center justify-center">
                        <div class="max-w-md">
                            <img class="object-cover object-center w-full rounded-md shadow" style="height: 500px;"
                                src="https://catalog-shop.s3.amazonaws.com/screen-shot.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'Showcase',
  data: function () {
    return {
      demoRequested: false,
      demo: {
        name: '',
        email: '',
        phone: '',
      },
    };
  },
  computed: {
    isRequestValid: function () {
      if (
        !this.demoRequested &&
        this.demo.name.trim() &&
        this.demo.email.trim() &&
        this.demo.phone.trim()
      )
        return true;
      else return false;
    },
  },
  methods: {
    clearRequest: function () {
      this.demo = {
        name: '',
        email: '',
        phone: '',
      };
    },
    sendRequest: async function () {
      try {
        await this.$store.dispatch('demo/sendRequest', this.demo);
        this.demoRequested = true;
      } catch (error) {
        console.log('Error sending demo request');
      }
    },
  },
};
</script>

<style>
</style>
