import { createStore } from 'vuex';

import shops from './shops';
import auth from './auth';
import demo from './demo';

export default createStore({
    state: () => ({}),
    mutations: {
        ['OK_MSG']: (state, payload) => {},
        ['INFO_MSG']: (state, payload) => {},
        ['WAR_MSG']: (state, payload) => {},
        ['ERROR_MSG']: (state, payload) => {}
    },
    modules: {
        auth: auth,
        shops: shops,
        demo: demo
    }
});