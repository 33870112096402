<template>
  <header class="bg-gray-800" x-data="{ isOpen: false }">
    <nav
      class="
        container
        px-6
        py-2
        mx-auto
        md:flex
        md:justify-between
        md:items-center
      "
    >
      <div class="flex items-center justify-between">
        <a href="#" class="py-1 px-2 mr-4 inline-flex items-center">
        <img src="@/assets/bzh-white.png" class="h-10 w-10 mr-2">
        <span class="text-xl text-white font-bold uppercase tracking-wide"
          >BAZAHU - BZH</span
        >
      </a>
        
        <!-- Mobile menu button -->
        <div @click="toggleMenu" class="flex md:hidden">
          <button
            type="button"
            class="
              text-gray-200
              hover:text-gray-400
              focus:outline-none
              focus:text-gray-400
            "
            aria-label="toggle menu"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div
        :class="isOpen ? 'flex' : 'hidden'"
        class="
          flex-col
          mt-2
          space-y-4
          md:flex
          md:space-y-0
          md:flex-row
          md:items-center
          md:space-x-10
          md:mt-0
        "
      >
        <a
          class="
            text-sm
            font-medium
            text-gray-200
            transition-colors
            duration-300
            transform
            hover:text-indigo-400
          "
          href="/"
          >Inicio</a
        >
        <a
          class="
            px-4
            py-1
            text-sm
            font-medium
            text-center text-gray-200
            transition-colors
            duration-300
            transform
            border
            rounded
            hover:bg-indigo-400
          "
          href="/"
          >Contacto</a
        >
        <a
          class="
            text-sm
            font-medium
            text-gray-200
            transition-colors
            duration-300
            transform
            hover:text-indigo-400
          "
          href="#"
          v-if="isAuthenticated"
          >{{user.name}} {{user.lastName}}</a
        >
        <a
          class="
            text-sm
            font-medium
            text-gray-200
            transition-colors
            duration-300
            transform
            hover:text-indigo-400
          "
          href="/"
          v-if="isAuthenticated"
          @click="logout"
          >Salir</a
        >
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function () {
    return { isOpen: false };
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters['auth/isAuthenticated'];
    },
    user: function () {
      return this.$store.getters['auth/getUser'];
    },
  },
  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout');
    },
    toggleMenu: function () {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style>
</style>