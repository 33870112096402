<template>
<div class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
   	<div class="absolute bg-black opacity-80 inset-0 z-0" @click="close"></div>
    <div class="w-full  max-w-3xl p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      <!--content-->
      <div class="">
        <!--body-->
        <div class=" p-5 flex-auto justify-center">
           <h2 class="text-2xl">{{title}}</h2> <br>
            <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title'],
  data: function () {
    return {};
  },
  methods: {
    ok: function () {
      this.$emit('close');
    },
    close: function () {
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>