<template>
  <div>
    <!-- Header -->
    <Header></Header>
    <router-view />

    <!-- Footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import toastr from 'toastr';
import axios from 'axios';

import Header from './components/Header';
import Footer from './components/Footer';

export default {
  name: 'App',
  created: function () {
    this.$store.subscribe((mutation) => {
      if (mutation.payload) {
        switch (mutation.type) {
          case 'OK_MSG':
            toastr.success(mutation.payload.content, mutation.payload.title);
            break;
          case 'INFO_MSG':
            toastr.info(mutation.payload.content, mutation.payload.title);
            break;
          case 'WAR_MSG':
            toastr.warning(mutation.payload.content, mutation.payload.title);
            break;
          case 'ERROR_MSG':
            toastr.error(mutation.payload.content, mutation.payload.title);
            break;
          case 'ADD_PRODUCT_SUCCESS':
            toastr.success('Producto guardado.', 'Correcto!');
            break;
          case 'UPDATE_PRODUCT_SUCCESS':
            toastr.success('Actualización completa.', 'Correcto!');
            break;
          case 'REMOVE_PRODUCT_SUCCESS':
            toastr.warning('Producto elminado.', 'Eliminado!');
            break;
        }
      }
    });


    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (401 === error.response.status) {
          console.log('error');
          this.$store.dispatch('auth/expired');
          this.$router.push({ name: 'Login' });
          return Promise.resolve(error.response);
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
</style>
