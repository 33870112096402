<template>
  <section class="features-head bg-primary py-3">
    <div class="container grid">
      <div>
        <h1 class="xl">Características</h1>
        <p class="lead">
          SimpleShop es una plataforma donde puedes iniciar tu propio negocio vendiendo productos por internet.
          No require inversión inicial ni contar con inventario físico.
        </p>

      </div>
      <img src="@/assets/images/server.png" alt="Server" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Head',
};
</script>

<style>
</style>