<template>
  <section
      class="relative flex items-center justify-center bg-gray-700"
      style="height: 500px"
    >
      <div class="absolute z-10 w-auto min-w-full min-h-full max-w-none">
        <img
          class="object-cover object-center w-full rounded-md shadow opacity-20"
          style="height: 500px"
          src="https://catalog-shop.s3.amazonaws.com/bg-image.png"
        />
      </div>
      <div class="text-center z-30">
        <p class="text-xl font-medium tracking-wider text-gray-200">
          Comienza tu negocio ahora
        </p>
        <h2 class="mt-6 text-3xl font-bold text-white md:text-5xl">
          La más amplia variedad de productos <br />
          para ofrecer a tus clientes
        </h2>

        <div class="flex justify-center mt-8">
          <a
            class="
              px-8
              py-2
              text-lg
              font-medium
              text-white
              transition-colors
              duration-300
              transform
              bg-indigo-600
              rounded
              hover:bg-indigo-500
            "
            href="#"
            >Contáctanos</a
          >
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'SubHeader'

}
</script>

<style>

</style>