<template lang="html">
  <div>
    <h1>No cuentas con privilegios para acceder a esta sección</h1>
  </div>
</template>

<script>
export default {
  name: 'Error403'
};
</script>

<style lang="css" scoped></style>
