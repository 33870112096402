<template>
  <div class="bg-gray-700 min-h-screen overflow-auto">
    <div class="container mx-auto px-4">
      <ShopList />
    </div>
  </div>
</template>

<script>
import ShopList from '../components/administration/ShopList';

export default {
  name: 'Administration',
  created: function () {
    this.$store.dispatch('shops/load');
  },
  components: {
    ShopList,
  },
};
</script>

<style>
</style>