<template>
  <div>
    <DocsHead />
    <DocsMain />
  </div>
</template>

<script>
import DocsHead from '../components/docs/DocsHead';
import DocsMain from '../components/docs/DocsMain';
export default {
  name: 'Docs',
  components: {
    DocsHead,
    DocsMain,
  },
};
</script>

<style>
</style>