import axios from 'axios';

const demos = {
    namespaced: true,
    state: () => ({

    }),
    getters: {

    },
    actions: {
        sendRequest({ commit }, payload) {
            return new Promise(
                (resolve, reject) => {
                    axios.post(`/apiv2/demos`, payload)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('DEMO_REQUESTED', response.data.demo);
                                resolve();
                            } else {
                                console.log('Error creating demo request');
                                reject();
                            }
                        }).catch(function(error) {
                            console.log('Error creating demo request');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },

    },
    mutations: {
        DEMO_REQUESTED(state, payload) {
            state.page = payload;
        }

    }
}

export default demos;