<template>
  <section class="docs-main my-4">
    <div class="container grid">
      <div class="card bg-light p-3">
        <h3 class="my-2">Essentials</h3>
        <nav>
          <ul>
            <li><a href="#" class="text-primary">Introduction</a></li>
            <li><a href="#">About ShoppingStore</a></li>
            <li><a href="#">Installation</a></li>
          </ul>
        </nav>
        <h3 class="my-2">Deployments</h3>
        <nav>
          <ul>
            <li><a href="#">Setting up a container</a></li>
            <li><a href="#">Using de CLI</a></li>
            <li><a href="#">Managin resources</a></li>
            <li><a href="#">Upgrade & downgrade</a></li>
          </ul>
        </nav>
      </div>
      <div class="card">
        <h2>Introduction</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro libero
          aut molestiae enim illum error qui fugiat quod repellendus debitis?
        </p>
        <div class="alert alert-success">
          <i class="fas fa-info"></i>Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Sapiente, beatae.
        </div>
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati,
          ex nemo placeat numquam quae delectus doloribus debitis tempora maxime
          dolor.
        </p>
        <a href="#" class="btn btn-primary">Install CLI</a>

        <h3>Requirements</h3>
        <ul>
          <li>Windows, Mac, Linux</li>
          <li>Node v12 or higher</li>
        </ul>

        <h3>Install</h3>
        <p>Mac (Homebrew)</p>
        <pre><code>brew install ShoppingStore</code></pre>
        <p>NPM</p>
        <pre><code>npm install ShoppingStore</code></pre>
        <p>Yarn</p>
        <pre><code>yarn install ShoppingStore</code></pre>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DocsMain',
};
</script>

<style>
</style>