<template>
  <section class="docs-head bg-primary py-3">
    <div class="container grid">
      <div>
        <h1 class="xl">Docs</h1>
        <p class="lead">
          Learn and discover how to get all features of the Shopping Store platform
        </p>
      </div>
      <img src="@/assets/images/docs.png" alt="Server" />
    </div>
  </section>
</template>

<script>
export default {
    name: 'DocsHead'
}
</script>

<style>

</style>