<template>
  <div class="">
    <h2 class=" text-3xl text-white">Resumen de pagos</h2>
    <div class="ui statistics">
      <div class="green tiny statistic">
        <div class="value">{{stats.payed}}</div>
        <div class="ui green label">Pagadas</div>
      </div>
      <div class="yellow tiny statistic">
        <div class="value">{{stats.pending}}</div>
        <div class="ui yellow label">Pendientes</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Statistics',
  props: ['stats']
};
</script>

<style>
</style>