import { createApp } from 'vue';
import 'es6-promise/auto';
import axios from 'axios';

import App from './App.vue';

import router from './router';
import store from './store';

import './assets/styles/style.css';
import "tailwindcss/tailwind.css";

const token = store.getters['auth/getAccessToken'];

if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


createApp(App).use(router).use(store).mount('#app')