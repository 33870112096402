<template>
  <footer class="border-t">
        <div class="container flex items-center justify-between px-6 py-8 mx-auto">
            <p class="text-gray-500">© 2021 Bazahu</p>
            <p class="font-medium text-gray-700"></p>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style>
</style>