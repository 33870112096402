import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import Features from "@/pages/Features";
import Docs from "@/pages/Docs";
import Administration from "@/pages/Administration";
import Error403 from '@/pages/errors/Error403';

import store from '../store';

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: { Auth: false, title: 'BZH' }
    },
    {
        path: "/features",
        name: "Features",
        component: Features,
        meta: { Auth: false, title: 'BZH' }
    },
    {
        path: "/docs",
        name: "Docs",
        component: Docs,
        meta: { Auth: false, title: 'BZH' }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { Auth: false, title: 'BZH' }
    },
    {
        path: '/403',
        name: 'Error403',
        component: Error403,
        meta: { Auth: false, title: 'Error' }
    },
    {
        path: "/administration",
        name: "Admnistration",
        component: Administration,
        meta: { Auth: true, title: 'Administración' }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async(to, from, next) => {
    document.title = to.meta.title;
    const token = store.getters['auth/getAccessToken'];

    if (to.meta.Auth && !token) {
        next({ name: 'Login' });
    } else {
        if (token && !store.getters['auth/isAuthenticated']) {
            await store.dispatch('auth/refresh');
        }
        const permissions = store.getters['auth/getPermissions'];

        if (
            (to.meta.permission && permissions.find(s => s === to.meta.permission)) ||
            !to.meta.permission
        ) {
            next();
        } else {
            // no permissions
            next({ name: 'Error403' });
        }
    }
});

export default router;