<template>
  <div class="ui login main container">
    <LoginForm :message="message" :isLoading="loading" @onLogin="login" />
    <div class="ui"></div>
  </div>
</template>

<script>
import LoginForm from '../components/administration/LoginForm';

export default {
  name: 'Login',
  data: function() {
    return { message: '', loading: false };
  },
  methods: {
    login: async function(payload) {
      this.loading = true;
      try {
        const resp = await this.$store.dispatch('auth/login', payload);
        this.$router.push({ name: 'Admnistration' });
      } catch (e) {
        this.message = e.message;
      } finally {
        this.loading = false;
      }
    }
  },
  components: { LoginForm }
};
</script>

<style lang="css" scoped>
.login {
  margin-top: 75px;
  height: 75vh;
}
</style>
