import axios from 'axios';

const shops = {
    namespaced: true,
    state: () => ({
        page: { docs: [], totalPages: 0, page: 1 },
        recordsPerPage: 100,
        count: 0
    }),
    getters: {
        getPageContent: state => state.page.docs,
    },
    actions: {
        load({ commit }) {
            return new Promise(
                (resolve, reject) => {
                    axios.get(`/apiv2/admin/shops`)
                        .then(response => {
                            commit('SHOP_PAGE_LOADED', response.data);
                            resolve();
                        }).catch(function(error) {
                            console.log('Error loading page');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
        create({ commit }, payload) {
            return new Promise(
                (resolve, reject) => {
                    axios.post(`/apiv2/admin/shops`, payload)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('SHOP_CREATED', response.data.shop);
                                resolve();
                            } else {
                                console.log('Error creating shop');
                                reject();
                            }
                        }).catch(function(error) {
                            console.log('Error creating shop');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
        update({ commit }, payload) {
            return new Promise(
                (resolve, reject) => {
                    axios.put(`/apiv2/admin/shops/${payload._id}`, payload)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('SHOP_UPDATED', response.data.shop);
                                resolve();
                            } else {
                                console.log('Error updating shop');
                                reject();
                            }
                        }).catch(function(error) {
                            console.log('Error updating shop');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },

        getById({ commit }, id) {
            return new Promise(
                (resolve, reject) => {
                    axios.get(`/apiv2/admin/shops/${id}`)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('SHOP_UPDATED', response.data.shop);
                                resolve(response.data.shop);
                            } else {
                                console.log('Error getting shop by id');
                                reject();
                            }

                        }).catch(function(error) {
                            console.log('Error getting shop by id');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
        addPayment({ commit }, payload) {
            return new Promise(
                (resolve, reject) => {
                    axios.post(`/apiv2/admin/shops/${payload.shopId}/payments`, payload)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('SHOP_UPDATED', response.data.shop);
                                resolve(response.data.shop);
                            } else {
                                console.log('Error getting shop by id');
                                reject();
                            }

                        }).catch(function(error) {
                            console.log('Error getting shop by id');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
        search({ commit }, subdomain) {
            return new Promise(
                (resolve, reject) => {
                    axios.get(`/apiv2/admin/shops/search`, {
                            params: {
                                q: subdomain
                            }
                        })
                        .then(response => {
                            if (response.data.status === 'OK') {

                                if (response.data.isValid) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }

                            } else {
                                console.log('Error searching shop domain');
                                reject();
                            }

                        }).catch(function(error) {
                            console.log('Error searching shop domain');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
        toggle({ commit }, shopId) {
            return new Promise(
                (resolve, reject) => {
                    axios.put(`/apiv2/admin/shops/${shopId}/toggle`)
                        .then(response => {
                            if (response.data.status === 'OK') {
                                commit('SHOP_UPDATED', response.data.shop);
                                resolve();
                            } else {
                                console.log('Error toggling status shop');
                                reject();
                            }
                        }).catch(function(error) {
                            console.log('Error toggling shop');
                            reject();
                        });

                    // Let the calling function know that http is done. You may send some data back
                },
                error => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        },
    },
    mutations: {
        SHOP_PAGE_LOADED(state, payload) {
            state.page = payload;
        },
        SHOP_CREATED(state, payload) {
            state.page.docs.push(payload);
        },
        SHOP_UPDATED(state, payload) {
            state.page.docs = state.page.docs.map(p => {
                if (p._id === payload._id) {
                    return payload;
                }
                return p;
            });
        }
    }
}

export default shops;